@import "src/styles/utils/_queries";
@import "src/styles/utils/_variables";

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Elements
.m-ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

// Typography
.m-h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 32px;
  }
}

.m-h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: medium;
  font-size: 22px;
  line-height: 22px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 32px;
  }
}

.m-h2-error {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  color: $red;
  margin-top: 30px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 32px;
  }
}

.m-h5 {
  color: $mine-shaft;
  margin-top: 18px;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

// Utils
.m-divider-full {
  width: 100%;
  height: 1px;
  background-color: silver;
  margin: 0px 0px;

  @include media-breakpoint-up(sm) {
    margin: 50px 0px;
  }
}

.m-divider {
  width: 100%;
  height: 1px;
  background-color: silver;
  margin: 40px 0px;

  @include media-breakpoint-up(sm) {
    margin: 50px 0px;
  }
}

.m-divider-step-2 {
  width: 100%;
  height: 1px;
  background-color: silver;
  margin: 40px 0px;

  @include media-breakpoint-up(sm) {
    margin: 50px 0px;
  }
}

.m-divider-dashed {
  width: 100%;
  height: 0px;
  border: 1px dashed silver;
  margin-top: 27px;
  margin-bottom: 30px;

  @include media-breakpoint-up(sm) {
    margin-top: 27px;
    margin-bottom: 30px;
  }
}

.m-divider-dashed-order {
  width: 100%;
  height: 0px;
  border: 1px dashed silver;
  margin-top: 27px;

  @include media-breakpoint-up(sm) {
    margin-top: 27px;
  }
}

.m-divider-dashed-error {
  width: 100%;
  height: 0px;
  border: 1px dashed silver;
  margin-top: 52px;
  margin-bottom: 46px;

  @include media-breakpoint-up(sm) {
    margin-top: 67px;
    margin-bottom: 55px;
  }
}

.m-divider-dashed-success {
  width: 100%;
  height: 0px;
  border: 1px dashed silver;
  margin-top: 17px;
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    margin-top: 29px;
    margin-bottom: 40px;
  }
}

.text-bold {
  font-weight: 800;
}

.m-container-full {
  width: 100%;
}

.m-container-medium {
  width: $container-width-medium;
}
