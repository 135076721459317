@import "src/styles/utils/_mixins";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/queries";

.paymentInstructionBox {
  display: flex;
}

.paymentInstructionText {
  font-size: 12px;
  color: $mine-shaft;
  padding-left: 15px;
  @include media-breakpoint-up(sm) {
    font-size: 14px;
    flex: 3;
  }
}

.paymentMethodItemImage {
  width: 90px;
  height: fit-content;
  border-radius: 5.2px;
  overflow: hidden;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  @extend %flex-center;
  display: inline-flex;
  transition: all $transition-duration ease;

  @include media-breakpoint-down(sm) {
    width: 120px;
  }
}

.paymentMethodItemImage img {
  width: 100%;
  height: 100%;
}
