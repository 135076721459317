@import "src/styles/utils/_variables";
@import "src/styles/utils/_queries";
@import "src/styles/utils/_placeholders";

.headerWrapper {
  background-color: $spindle;
  padding: 25px 20px;
  min-width: 124px;
  @extend %flex-center;

  @include media-breakpoint-up(sm) {
    min-width: 143px;
  }
}

.headerContentBox {
  text-align: center;

  @extend %flex-center;
}

.textBox {
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  flex: 2;

  @include media-breakpoint-up(sm) {
    margin-right: 25px;
  }
}

.headerLogo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 95px;
  height: 70px;
  flex: 1;

  @include media-breakpoint-up(sm) {
    width: 135px;
    height: 100px;
  }
}

.buttonDropdownWrapper {
  margin-left: auto;
}

.headerContainer {
  max-width: $container-width-large;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerTitle {
  font-size: 18px;
  font-weight: 900;
}

.headerDescription {
  font-size: 12px;
  color: $boulder;

  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
}
