@import "src/styles/utils/variables";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/_queries";

// Item wrapper
.navigationItemWrapper {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  @extend %flex-center;
  @extend %disabled-highlight;
  flex-direction: column;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.navigationItemWrapperActive {
  .navigationItemCircle {
    background-color: $spindle;

    transition: background-color $transition-duration ease;
  }

  .navigationItemNumber {
    color: $mine-shaft;
    transition: color $transition-duration ease;
  }

  .navigationItemText {
    font-weight: 700;
    color: $mine-shaft;
  }
}

.navigationItemCircle {
  background-color: $gallery;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @extend %flex-center;

  @include media-breakpoint-up(sm) {
    width: 44px;
    height: 44px;
  }
}

.navigationItemNumber {
  color: $gray;
  font-size: 24px;
}

.navigationItemText {
  font-size: 12px;
  line-height: 12px;
  color: $gray;
  margin-top: 8px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin-left: 10px;
    margin-top: 0;
    line-height: 15px;
    text-align: left;
  }
}
