@import "src/styles/utils/_mixins";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/queries";

.oderParamsItem {
  max-width: 100%;
}

.oderTotal {
  color: $mine-shaft;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.footerRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.footerRowHighlighted {
  color: $red;
}
