@import "src/styles/utils/_queries";
@import "src/styles/utils/variables";
@import "src/styles/utils/_variables";
@import "src/styles/utils/_queries";
@import "src/styles/utils/_placeholders";

.containerWrapper {
  min-width: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;

  @include media-breakpoint-up(sm) {
    min-width: 143px;
  }
}

.errorBox {

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
  }
}

.errorIcon {
  height: 50px;
}

.title {
  font-family: Roboto;
  font-weight: 900;
  font-size: 102px;
  line-height: 102px;
  padding-bottom: 33px;
  margin-right: 0;

  @include media-breakpoint-up(sm) {
    font-size: 112px;
    line-height: 112px;
    margin-right: 50px;
  }
}

.subTitle {
  font-family: Roboto;
  font-weight: 600;
  font-size: 34px;

  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }
}

.subTitleLight {
  font-family: Roboto;
  font-weight: 400;
  font-size: 34px;
  padding-top: 55px;

  @include media-breakpoint-up(sm) {
    font-size: 30px;
  }
}

.errorMessage {
  color: $red;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto";
  line-height: 21.09px;
  margin-top: 31px;

  @include media-breakpoint-up(sm) {
    margin-top: 16px;
  }
}


.screenContainer {
  margin: 43px 20px;

  @include media-breakpoint-up(sm) {
    margin: 67px 116px;
    width: $container-width-large;
    align-self: center;
  }
}
