@import "src/styles/utils/_queries";

// Navigation wrapper
.stepperNavigationWrapper {
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  justify-content: space-between;
}
