@use "sass:color";
@import "./_variables";

@mixin hover-lightness-bg($color) {
  background-color: color.adjust($color, $lightness: -5%);
  transition: all $transition-duration ease;
}
@mixin hover-lightness-color($color) {
  color: color.adjust($color, $lightness: -5%);
  transition: all $transition-duration ease;
}
