@import "src/styles/utils/queries";
@import "src/styles/utils/variables";

.oderWithFees {
  margin-top: 17px;

  @include media-breakpoint-up(sm) {
    margin-top: 33px;
  }
}

.buttonWrapper {
  margin-top: 50px;

  @include media-breakpoint-up(sm) {
    margin-top: 69px;
  }
}

.buttonWrapper2 {
  margin-top: 41px;

  @include media-breakpoint-up(sm) {
    margin-top: 29px;
  }
}

.checkmarkIcon {
  width: 90px;
  height: 90px;
  margin-bottom: 23px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 25px;
  }
}

.oderFees {
  color: $mine-shaft;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.oderTotal {
  color: $mine-shaft;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
    margin-top: 5px;
  }
}

.feesLabel {
  color: $gray;
}

.errorMessage {
  color: $red;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto";
  line-height: 21.09px;
  margin-top: 31px;

  @include media-breakpoint-up(sm) {
    margin-top: 16px;
  }
}

.infoRow {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.info {
  margin-top: 7px;

  @include media-breakpoint-up(sm) {
    margin-top: 25px;
  }
}

.infoLabel {
  color: $mine-shaft;
}

.infoValue {
  color: $gray;
}
