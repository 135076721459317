@import "src/styles/utils/_variables";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/_queries";

.layoutImage {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("./../../assets/layout_image.webp");
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(2, 48, 75, 0.75);
}

.layoutContentContainer {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1160px) and (min-width: 768px) {
  .formContainer{
    position: relative;
    overflow: hidden;
  }
}
