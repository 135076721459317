@import "src/styles/utils/mixins";
@import "src/styles/utils/queries";

.attachmentContainer {
  .attachmentItem {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    &:before {
      content: "\1F4CE";
      font-size: 1em;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .fileLink {
    color: $dove-gray;
    font-size: 16px;

    &:hover {
      color: $gray-800 !important;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
}
