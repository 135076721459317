%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%disabled-highlight {
  user-select: none; /* standerd */
  -moz-user-select: none; /* firefox specific */
  -webkit-user-select: none; /* Chrome, Opera and Safari*/
  -ms-user-select: none; /* IE, ms-edge */
}
