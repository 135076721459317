@import "src/styles/utils/variables.scss";
@import "src/styles/utils/queries";

.dialogCard {
  width: 100%;
  background-color: $white;
  border-radius: 5.2px;
  padding: 40px 40px;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }
}

.bodyWrapper {
  margin-top: 26px;

  @include media-breakpoint-up(sm) {
    margin-top: 36px;
  }
}
