@import "src/styles/utils/_mixins";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/queries";

.label {
  font-size: 14px;
  font-weight: bold;
  color: $tundora;
  display: flex;
  margin-left: 1px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.oderPriceTotal {
  color: $tundora;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 5px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 8px;
  }
}
