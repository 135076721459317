@import "src/styles/utils/_variables";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/_queries";

.paymentMethodItemWrapper {
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.paymentMethodItemWrapperActive {
  .paymentMethodItemCard {
    opacity: 1;
    transition: all $transition-duration ease;

    &::after {
      opacity: 1;
      transition: all $transition-duration ease;
    }
  }

  .checkmarkIcon {
    opacity: 1;
    transition: all $transition-duration ease 0.1s;
  }

  .paymentMethodItemText {
    font-weight: 700;
    color: $curious-blue;

    transition: all $transition-duration ease;
  }

  .paymentMethodItemImage {
    &::after {
      opacity: 0.3;
    }
  }
}

.paymentMethodItemWrapperDisable {
  .paymentMethodItemCard {
    opacity: 1;
    pointer-events: none;
  }

  .paymentMethodItemText {
    font-weight: normal;
    color: $alto;
  }

  .paymentMethodItemImage {
    opacity: 0.3;
  }
}

.paymentMethodItemCard {
  width: 100%;
  height: 62px;
  border-radius: 5.2px;
  overflow: hidden;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  cursor: pointer;
  @extend %flex-center;
  display: inline-flex;
  transition: all $transition-duration ease;

  @include media-breakpoint-up(sm) {
    height: 72px;
  }

  &::after {
    opacity: 0;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 5.2px;
    position: absolute;
  }

  &:hover {
    .paymentMethodItemImage {
      &::after {
        opacity: 0.3;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    flex: 2;
  }
}

.paymentMethodItemImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 115%;
  height: 110%;
  border-radius: 5.2px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    opacity: 0;
    height: 100%;
    background-color: #000;
    transition: all $transition-duration ease;
  }
}

.checkmarkIcon {
  position: absolute;
  z-index: 10;
  font-size: 27px;
  opacity: 0;
  transition: all $transition-duration ease;
}

.paymentMethodItemText {
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: $boulder;
  white-space: pre-wrap;
  min-height: 28px;

  @include media-breakpoint-up(sm) {
    flex: 1;
    font-size: 16px;
    line-height: 22px;
    min-height: 40px;
  }
}
