@import "src/styles/utils/variables.scss";
@import "src/styles/utils/_placeholders.scss";
@import "src/styles/utils/_queries.scss";

.input {
  background: $white;
  border: 1px solid $silver;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(75, 75, 75, 0.25);
  border-radius: 8px;
  padding: 10px 20px;
  color: $dusty-gray;
  outline: none;
  height: 44px;
  font-size: 18px;
}

.label {
  font-size: 14px;
  font-weight: 700;
  color: $tundora;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-left: 1px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.message {
  font-size: 12px;
  margin-top: 6px;
}

.editButton {
  margin-right: 2px;
  font-size: 14px;
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
  color: $curious-blue;
  font-weight: 400;
  display: inline-block;

  @extend %disabled-highlight;
}

.wrapper {
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
}

.wrapperError {
  .input {
    border: 1px solid $red;
  }

  .label {
    color: $red;
  }

  .message {
    color: $red;
  }
}

.wrapperDisabled {
  .input {
    background-color: $gallery;
    box-shadow: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input[type="number"] {
//   -moz-appearance: textfield;
// }
// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }
