@import "src/styles/utils/variables";
@import "src/styles/utils/_placeholders";

%hoverEffect {
  &:hover {
    background-color: $kashmir-blue;
    transition: all $transition-duration ease;
    color: white;

    .buttonDropdownText {
      color: white;
    }

    .buttonDropdownArrow {
      color: white;
    }
  }
}

.buttonDropdownContainer {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.buttonDropdownWrapper {
  display: flex;
  align-items: center;
  border: 2px solid $kashmir-blue;
  padding: 8px 10px;
  @extend %disabled-highlight;
  cursor: pointer;
  transition: all $transition-duration ease;

  @extend %hoverEffect;
}

.buttonDropdownText {
  margin-right: 8px;
  color: $tall-poppy;
  font-weight: 700;
}

.buttonDropdownListOption {
  color: $tall-poppy;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  @extend %hoverEffect;
}

.buttonDropdownListOptionOpen {
  display: flex;
}

.buttonDropdownArrow {
  font-size: 14px;
  color: $kashmir-blue;
  transform: rotate(180deg);
  margin-left: auto;
}

.buttonDropdownExpansion {
  border: 2px solid $kashmir-blue;
  width: 100%;
  display: none;
  position: absolute;
  bottom: -49px;
  height: 100%;
  align-items: center;
}

.buttonDropdownExpansionOpen {
  display: flex;
}
