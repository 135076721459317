@import "src/styles/utils/_queries";
@import "src/styles/utils/variables";

.screenContainer {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-inline: 10px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    margin: 95px 10px;
    align-self: center;
    max-width: $container-width-large;
  }
  
}
