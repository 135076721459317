@import "src/styles/utils/variables.scss";
@import "src/styles/utils/_placeholders.scss";
@import "src/styles/utils/_mixins.scss";
@import "src/styles/utils/_queries.scss";

.button {
  @extend %flex-center;
  @extend %disabled-highlight;
  border-radius: 8px;
  height: 50px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border: none;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.primary {
  background-color: $curious-blue;
  font-weight: 900;
  color: $white;

  &:hover {
    @include hover-lightness-bg($curious-blue);
  }
}

.secondary {
  background-color: $silver;
  font-weight: 500;
  color: $tundora;

  &:hover {
    @include hover-lightness-bg($silver);
  }
}

.disabled {
  background-color: $alto;
  font-weight: 500;
  color: $white;

  &:hover {
    background-color: $alto;
  }
}
