@import "src/styles/utils/_mixins";
@import "src/styles/utils/_placeholders";
@import "src/styles/utils/queries";

.oderParamsItem {
  max-width: 100%;
}

.oderTotal {
  color: $mine-shaft;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.footerRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.footerRowHighlighted {
  color: $red;
}




// ######### Accordion Styles
.accordionItemWrapper {
  padding-bottom: 5px;
}

.accordionItemWrapperExpanded {
  .accordionItemExpansion {
    padding: 25px 17px;
    max-height: 370px;
    margin-top: 5px;
    border-radius: 5.2px;
    background-color: $porcelain;
    transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 0.3s ease,
    margin-top 0.1s ease 0.15s;
  }

  .accordionItemExpansionContentWrapper {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.15s ease 0.25s;
    max-width: 100%;
    @include media-breakpoint-up(sm) {
      max-width: 90%;
    }
  }

  .accordionItemArrowIcon {
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
}

.accordionItemCard {
  width: 100%;
  height: 59px;
  background-color: $humming-bird;
  border-radius: 5.2px;
  padding: 0px 17px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }
}

.accordionHeading {
  padding: 6px 18px;
  color: $dove-gray;
}

.accordionHeadingRow {
  font-size: 12px;
  font-weight: 400;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }
}

.accordionFooter {
  color: $mine-shaft;
  margin-top: 18px;
  font-size: 14px;
  padding: 0 17px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.accordionInvalidAmount {
  color: $red;
}

.accordionItemCardTitle {
  max-width: 150px;
  @include media-breakpoint-up(sm) {
    max-width: 250px;
  }
}

.accordionItemPriceWrapper {
  display: flex;
  justify-content: flex-start;
}

.accordionItemQuantityWrapper {
  display: flex;
  justify-content: flex-start;
}

.accordionItemExpansion {
  border-radius: 0px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  margin-top: -2px;
  font-family: "Roboto", sans-serif;
  background-color: $humming-bird;
  font-size: 14px;
  transition: max-height 0.2s cubic-bezier(0.4, 0, 0.2, 1), padding 0.1s ease;
}

.accordionItemRow {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  flex: 1;
  align-items: center;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

.accordionItemPrice {
  font-weight: 900;
}

.accordionItemArrowIcon {
  font-size: 14px;

  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.accordionItemArrowWrapper {
  justify-content: flex-end;
  display: flex;
  margin-right: 10px;
  align-items: center;
  padding: 0;

  cursor: pointer;

  @extend %disabled-highlight;

  &:hover {
    @include hover-lightness-color($black);
  }
}

.accordionItemExpansionTitle {
  color: $mine-shaft;
  font-weight: 500;
}

.accordionItemExpansionContent {
  color: $dove-gray;
  font-weight: 400;
}

.accordionItemExpansionContentWrapper {
  margin-bottom: 20px;
  visibility: hidden;

  opacity: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }
}

.accordion {
  max-width: 100%;
}


// For Product Quantity Editing
.product_details_value{
  display: flex;
  flex-direction: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
