@import "src/styles/utils/_placeholders";
@import "src/styles/utils/queries";

.loadingScreenWrapper {
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(2, 48, 75, 0.75);
  position: absolute;
  display: flex;
  opacity: 0;
  justify-content: center;
  transition: all 0.15s ease-out;
  visibility: hidden;
}

.loadingScreenWrapperActive {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.loadingScreenWrapperLight {
  background: #aed4eb;
}

.loadingScreenContent {
  margin-top: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  @include media-breakpoint-up(sm) {
    margin-top: 250px;
  }
}

.loadingScreenText {
  padding: 8px 0;
  color: white;
  font-size: 18px;
}
