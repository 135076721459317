@import "src/styles/utils/_queries";
@import "src/styles/utils/variables";

.screenContainer {
  margin: 50px 20px;

  @include media-breakpoint-up(sm) {
    margin: 95px 120px;
    width: $container-width-large;
    align-self: center;
  }
}

.copyWriteContainer {
  width:120px; 
  margin:0 auto;
}

.copyWriteText {
  text-align: center
}

@import "src/styles/utils/_mixins";
@import "src/styles/utils/queries";

.firstHeader {
  margin-bottom: 30px;
}

.firstScreenWrapper {
  margin-top: 30px;
}

.accordionWrapper {
  margin-top: 26px;

  @include media-breakpoint-up(sm) {
    margin-top: 36px;
  }
}

.paymentMethodContainer {
  margin-top: 26px;
}

.fieldWrapper {
  margin-bottom: 19px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 16px;
  }
}

.mobileMoneyWrapper {
  margin-top: 24px;
  display: none;

  @include media-breakpoint-up(sm) {
    margin-top: 35px;
  }
}

.mobileMoneyWrapperVisible {
  display: block;
}

.buttonWrapper {
  margin-top: 35px;

  @include media-breakpoint-up(sm) {
    margin-top: 53px;
  }
}

.buttonWrapper2 {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
}

// ----------------------------------------------------------------
//  ADD AND SUBTRACT QUANTITY STYLE
// ----------------------------------------------------------------

.product_details_value {
  display: flex;
  flex-direction: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.quantity_editor {
  display: flex;
  border: 1px solid #aed4eb;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: min-content;
}

.quantity_editor span {
  background-color: inherit;
  padding-inline: 14px;
  border: 1px solid #aed4eb;
  font-size: 20px;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.quantity_editor span:focus {
  outline: none;
}

.quantity_editor input {
  width: 55px;
  border: 1px solid #aed4eb;
  text-align: center;
}

.quantity_editor input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity_editor input:focus {
  outline: none;
}




// ================================== Product payment success

.checkmarkIcon {
  width: 90px;
  height: 90px;
  margin-bottom: 23px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 25px;
    width: 40px;
  }
}


// ========================================
.paymentLogo {
  width: 65px;
  margin-bottom: 20px;
  flex: 1;

  @include media-breakpoint-up(sm) {
    width: 135px;
    height: 100px;
  }
}

.waitText{
  margin-top: 20px;
}