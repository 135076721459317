// Colors
$alto: #d3d3d3;
$curious-blue: #2686c0;
$white: #ffffff;
$tundora: #464545;
$silver: #b9b9b9;
$dusty-gray: #9b9b9b;
$red: #e50000;
$gallery: #ededed;
$gray: #8d8d8d;
$spindle: #aed4eb;
$mine-shaft: #242424;
$humming-bird: #e0f1fb;
$black: #000000;
$porcelain: #ecedee;
$tall-poppy: #c02626;
$kashmir-blue: #537799;
$dove-gray: #6c6565;
$boulder: #777777;
$spindle: #aed4eb;

// Other
$transition-duration: 0.2s;
$container-width-large: 570px;
$container-width-medium: 470px
